import styled from "styled-components"
import { Link as LinkGatsby } from "gatsby"

export const Link = styled(LinkGatsby)`
  width:  ${({ linkWidth }) => linkWidth ? `${linkWidth}` : '100%'};;
  border-radius: 8px;

  .secondary {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.secondary.health_green};
    border: 1px solid ${({ theme }) => theme.colors.secondary.health_green};

    &:hover {
      background-color: ${({ theme }) => theme.colors.green["100"]};
    }
  }
`

export const ButtonContainer = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary.health_green};
  color: ${({ theme }) => theme.colors.background};
  font-size: 1.125rem;
  font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};
  padding: 0.625rem 2rem;
  height: 3rem;
  width: ${({ maxWidth }) => maxWidth ? `${maxWidth}rem` : '100%'};
  max-width: ${({ maxWidth }) => maxWidth ? `${maxWidth}rem` : 'inherit'};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s linear;
  box-shadow: ${({ shadowy }) =>
    shadowy
      ? "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.3)"
      : "none"};

  &:hover {
    background-color: ${({ theme }) => theme.colors.green["700"]};
  }

  &.outlined {
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.secondary.txt_green};
    border: 2px solid ${({ theme }) => theme.colors.secondary.health_green};
  }

  &.outlined:hover:not(.disabled) {
    background: ${({ theme }) => theme.colors.green[100]};
  }

  &.sensitive {
    background: ${({ theme }) => theme.palette.alert.dark};
  }

  &.sensitive:hover:not(.disabled) {
    background: ${({ theme }) => theme.palette.error.main};
  }

  &.disabled {
    color: ${({ theme }) => theme.colors.gray.dark};
    background-color: ${({ theme }) => theme.colors.gray.disable};
    cursor: not-allowed;
  }
`
