import { CircularProgress, circularProgressClasses } from "@mui/material"
import React, { useMemo } from "react"

import { ButtonContainer, Link } from "./styles"

export default function Button({
  link,
  title,
  type = "button",
  disabled = false,
  onClick,
  variant = false,
  large = false,
  loading = false,
  shadowy,
  noLink = false,
  maxWidth,
  linkWidth,
}) {
  const Wrapper = useMemo(
    () =>
      noLink
        ? ({ children }) => <>{children}</>
        : ({ children }) => (
            <Link to={link} linkWidth={linkWidth} onClick={onClick}>
              {children}
            </Link>
          ),
    [noLink, link, onClick]
  )

  return (
    <Wrapper>
      <ButtonContainer
        disabled={loading || disabled}
        type={type}
        className={[variant, disabled && "disabled"]}
        large={large}
        shadowy={shadowy}
        onClick={noLink ? onClick : undefined}
        maxWidth={maxWidth}

      >
        {loading ? (
          <CircularProgress
            size={20}
            color="inherit"
            thickness={4}
            sx={{
              [circularProgressClasses.circle]: {
                fill: "#fff !important",
                color: "#fff !important",
              },
            }}
          />
        ) : (
          <>{title}</>
        )}
      </ButtonContainer>
    </Wrapper>
  )
}
